import PropTypes from 'prop-types'
import React from 'react'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'

const FloatingFinishOrder = ({ onClick, total, label }) => {
	const { formatPrice } = useStringFormater()

	return (
		<div className='floating-finish-order floating-card'>
			<span className='text-11px-400 color-gray-light message'>
				Incluye los productos mayoristas, generales y costos de envío
			</span>
			<button className='btn-filled finish-btn -blue' onClick={onClick}>
				<span>{label}</span>
				<span className='text-18px-700'>{formatPrice(total)}</span>
			</button>
		</div>
	)
}

FloatingFinishOrder.propTypes = {
	onClick: PropTypes.func.isRequired,
	total: PropTypes.number,
	laben: PropTypes.string
}

FloatingFinishOrder.defaultProps = {
	onClick: () => {},
	total: 0,
	label: 'Ir a pagar'
}

export default FloatingFinishOrder
