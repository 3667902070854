/* eslint-disable no-unreachable */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getTotalDiscountValue,
  validateOffers
} from '../../hooks/offers/useOffers'
import { routes } from '../../routes/routes'
import Summary from './Summary'
import sectionGenerator from './sectionGenerator'
// hooks
import { Box } from '@material-ui/core'
import useAuth from '../../hooks/auth/useAuth'
import useConfigs from '../../hooks/configs/useConfigs'
import useOffers from '../../hooks/offers/useOffers'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
import FloatingFinishOrder from '../../pages/Cart/CartMobile/Resumen/FloatingFinishOrder'
import SummaryButton from '../atoms/buttons/SummaryButton'

const ShoppingCartSummary = (props) => {
  const {
    visualizationMode,
    validCupon,
    paymentMethod,
    loading,
    setShowModal,
    isMobile
  } = props

  const history = useHistory()
  const [localLoading, setLocalLoading] = useState(false)
  const [errorSendOrder, setErrorSendOrder] = useState("")
  const [goToPayOnlineLink, setGoToPayOnlineLink] = useState("")
  const { isLogged, showLoginModal, authValues, getCityBranchOffice } =
    useAuth()

  const {
    generalTotal,
    wholesalerTotal,
    wholesalerProducts,
    generalProducts,
    cartTotal: subTotal,
    sendOrder,
    getInactiveProducts,
    deliveryValue,
    loadingOrder,
    setLoadingOrder
  } = useShoppingCart()

  const { consumeCupon, offers } = useOffers()

  const { bag, getBags /*, bags*/ } = useConfigs()

  const haveAddresses = authValues.addresses.length > 0
  const completeRegister = authValues.phone === "" || authValues.phone.length < 4 || authValues.document === ""

  const branch = getCityBranchOffice()

  const anyProductSelected =
    generalProducts.length + wholesalerProducts.length > 0

  //Totals
  const [total, setTotal] = useState(0)
  //const [totalOrderDiscount, setTotalOrderDiscount] = useState(null)
  const [totalBags, setTotalBags] = useState(0) // Total Bag

  //Discounts
  const [discountValue, setDiscountValue] = useState(null)
  const [discounts, setDiscounts] = useState(null)

  //Obtener el precio de bolsas de acuerdo a el totalNeto
  const getTotalBags = () => {
    setTotalBags(getBags(subTotal) * bag.bag_price)
  }

  const getDiscounts = () => {
    let fullProducts = []
    for (let i = 0; i < authValues.shopping_cart.length; i++) {
      fullProducts = [
        ...fullProducts,
        {
          ...authValues.shopping_cart[i],
          provider: authValues.shopping_cart[i].provider,
          brand: authValues.shopping_cart[i].brand,
          main_category_id: authValues.shopping_cart[i].main_category_id,
          sub_category_id: authValues.shopping_cart[i].sub_category_id,
          id_item: authValues.shopping_cart[i].id_item
        }
      ]
    }

    if (offers.length === 0 || fullProducts.length === 0 || !branch) {
      return
    }
    //console.log("validateOffers");

    const validOffers = validateOffers(offers, fullProducts, branch) //branch provisional
    // console.log('validOffers', validOffers, offers, fullProducts, branch)

    //cálculo de descuentos para un pedido
    if (validOffers.length > 0 || validCupon) {
      // Set discounts values offers
      const totalDiscount = getTotalDiscountValue(
        validCupon ? [...validOffers, validCupon] : validOffers,
        subTotal,
        deliveryValue,
        fullProducts
      )

      let roundValueDiscount = Math.round(totalDiscount, -2)
      setDiscountValue(roundValueDiscount)
      setDiscounts(validCupon ? [...validOffers, validCupon] : validOffers)
      return true
    } else {
      setDiscountValue(null)
      setDiscounts(0)
      return false
    }
  }

  useEffect(() => {
    getTotalBags()
  }, [subTotal, bag.bag_price]) // se ejecutará de nuevo si la dependecia cambia

  useEffect(() => {
    getDiscounts()
  }, [authValues.shopping_cart, validCupon]) // se ejecutará de nuevo si las dependecias cambian

  //UseEffect Para calcular total
  useEffect(() => {
    // console.log("UseEffect Para calcular total");

    if (deliveryValue > 0) {
      if (discountValue) {
        const subTotalWithDiscount = subTotal - discountValue
        const totalWithDiscount =
          subTotalWithDiscount + deliveryValue + totalBags
        setTotal(totalWithDiscount)
      } else {
        const total = subTotal + deliveryValue + totalBags
        setTotal(total)
      }
    }
  }, [subTotal, discountValue, totalBags, deliveryValue])

  /* 	 Maneja el proceso de pago de un pedido en una aplicación web o móvil,
   incluyendo la verificación de inicio de sesión, el envío de la orden,
   la aplicación de descuentos y la redirección a la página de confirmación del pedido. */
  const pay = async () => {
    if (loadingOrder) {
      return
    }
    if (!isLogged) return showLoginModal();
    // return console.log("paymentMethod", paymentMethod);
    setLocalLoading(true)
    sendOrder(discountValue, discounts, paymentMethod, (result) => {
      // console.log("result sendOrder", result);
      setLocalLoading(false)
      if (validCupon) {
        consumeCupon(validCupon.code, validCupon.id, authValues.id, authValues)
      }
      if (result) {
        if (result.onlinePayment.formUrl) {
          const openned = window.open(result.onlinePayment.formUrl, '_blank');
          if (openned) {
            return history.push(routes.orderFinished.path)
          }
          return setGoToPayOnlineLink(result.onlinePayment.formUrl)
        }
      }
      history.push(routes.orderFinished.path)
    }, (err) => {
      setLocalLoading(false)
      setErrorSendOrder(err)
    })
  }

  const actionLabel = !anyProductSelected
    ? 'No hay productos'
    : completeRegister ? "Completa tu registro para continuar" 
    :!haveAddresses
      ? 'Sin direcciones registradas'
      : 'Completar pedido'

  const actionLabelMobile = completeRegister ? "Completa tu registro para continuar" : "Ir a pagar"

  /* 		 control de flujo que maneja la selección de productos y el pago. Si hay algún producto inactivo,
     el usuario es notificado y se le impide realizar el pago. De lo contrario,
     si el usuario ha seleccionado productos y hay una dirección de envío disponible, se permite el pago */
  const onClick = async () => {
    if(completeRegister === true){
      return history.push("/account/edit_data")
    }else{
      if (!haveAddresses) {
        return history.push("/account/addresses")
      }
    }
    setLoadingOrder(true)
    const inactiveProducts = await getInactiveProducts()
    // console.log(inactiveProducts.length)
    if (inactiveProducts.length > 0) {
      setShowModal(inactiveProducts)
    } else {
      if (anyProductSelected && haveAddresses) {
        pay()
      }
    }
  }

  const sections = []

  const generateDiscountsString = (discounts) => {
    const discountNames = discounts.map((discount) => {
      const discountName = discount.type === 'cupon' ? 'Cupón' : 'Promoción'
      return `${discountName}: ${discount.name}`
    })

    return discountNames
  }

  if (wholesalerProducts.length > 0) {
    sections.push(
      sectionGenerator({
        title: 'Generales',
        subtitle: `${generalProducts.length} item${generalProducts.length > 1 ? 's' : ''
          }`,
        price: generalTotal,
        withBorder: true
      })
    )
    sections.push(
      sectionGenerator({
        title: 'Mayorista',
        subtitle: `${wholesalerProducts.length} item${wholesalerProducts.length > 1 ? 's' : ''
          }`,
        price: wholesalerTotal,
        withBorder: true
      })
    )
  }
  sections.push(
    sectionGenerator({
      title: 'Subtotal',
      price: subTotal,
      message: 'Incluye los productos generales y mayoristas',
      withBorder: true
    })
  )
  if (discounts) {
    sections.push(
      sectionGenerator({
        title: `Descuentos`,
        subtitle: generateDiscountsString(discounts),
        price: -discountValue,
        withBorder: true
      })
    )
  }
  if (haveAddresses) {
    sections.push(
      sectionGenerator({
        title: 'Costo de envío',
        price: deliveryValue,
        withBorder: true
      })
    )
  }
  sections.push(
    sectionGenerator({
      title: 'Impuesto bolsa',
      price: totalBags,
      withBorder: true
    })
  )
  {
    total && sections.push(
      sectionGenerator({
        title: 'Total',
        price: total
      })
    )
  }

  return (
    <>
      {goToPayOnlineLink && <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
        <i className='pse-icon' />
        <h5>Si no fuiste redireccionado haz click aqui:</h5>
        <SummaryButton
          hidePrice={true}
          asLink={true}
          actionLabel={"Realizar pago en linea"}
          price={total}
          href={goToPayOnlineLink}
          onClick={() => {
            if (!haveAddresses) {
              history.push("/account/addresses")
            }
            history.push(routes.orderFinished.path)
            setGoToPayOnlineLink("")
          }}
        // disabled={loadingOrder}
        />
      </Box>}
      {errorSendOrder && <h5>
        {errorSendOrder}
      </h5>}
      {!goToPayOnlineLink && <Summary
        loading={loading || localLoading}
        loadingOrder={loadingOrder}
        actionLabel={actionLabel}
        visualizationMode={visualizationMode}
        hidePrice={!anyProductSelected || !haveAddresses}
        onClick={onClick}
        sections={sections}
        total={total}
      />}

      {isMobile && <FloatingFinishOrder onClick={onClick} total={total} label={actionLabelMobile}/>}
    </>
  )
}

ShoppingCartSummary.propTypes = {
  visualizationMode: PropTypes.bool,
  validCupon: PropTypes.object,
  paymentMethod: PropTypes.number,
  loading: PropTypes.bool,
  setShowModal: PropTypes.func,
  isMobile: PropTypes.bool
}

export default ShoppingCartSummary
